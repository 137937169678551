
import { useEffect, useState } from 'react';
import './App.css';




function App() {

  

  const [Loading, setLoading] = useState(true)

  useEffect(() => {
    
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [])

  return (
    <div className="container-fluid" style={{
      width: "100%",
      display : 'flex',
      flexDirection : 'column',
      justifyContent : 'center',
      alignItems : 'center',
      backgroundColor: "#385981"
    }}>

      <video src='koby with sub_0.mp4' autoPlay={true} controls={true} width={"90%"} style={{
        marginTop: 50,
        marginBottom : 50
      }} />

      <div style={{
        whiteSpace: 'pre-wrap',
        width : '80%',
        marginTop : 20,
        color : 'white',
        fontSize : 24,
        textAlign: 'center',
        marginBottom : 50
      }}>לאתר של המרכז העירוני לעסקים לעוד מידע, זכויות שלכם בעלי העסקים ותוכן מומלץ:
      <br/>
      <a className='btn btn-light' href="https://mbc.org.il/">לחץ כאן לאתר</a>
      <br/>
      <br/>
להצטרפות למרכז העירוני לעסקים ראשון לציון והצטרפות לרשימת דיוור הכוללת מכרזים עירוניים, כל מידע אשר רלוונטי לעסק שלך ללא עלות :)
<br/>
      <a className='btn btn-light' href="https://biznese.co.il/land_form.aspx?name=null&g=4f490da5-5b2f-8294-4df5-1704d0cfd7da">לחץ כאן להצטרפות</a></div>


      <style>
        {`
          .loader,
          .loader:before,
          .loader:after {
            border-radius: 50%;
          }
          .loader {
            color: #ffffff;
            font-size: 11px;
            text-indent: -99999em;
            margin: 55px auto;
            position: relative;
            width: 10em;
            height: 10em;
            box-shadow: inset 0 0 0 1em;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
          }
          .loader:before,
          .loader:after {
            position: absolute;
            content: '';
          }
          .loader:before {
            width: 5.2em;
            height: 10.2em;
            background: #ffffff;
            border-radius: 10.2em 0 0 10.2em;
            top: -0.1em;
            left: -0.1em;
            -webkit-transform-origin: 5.1em 5.1em;
            transform-origin: 5.1em 5.1em;
            -webkit-animation: load2 2s infinite ease 1.5s;
            animation: load2 2s infinite ease 1.5s;
          }
          .loader:after {
            width: 5.2em;
            height: 10.2em;
            background: #ffffff;
            border-radius: 0 10.2em 10.2em 0;
            top: -0.1em;
            left: 4.9em;
            -webkit-transform-origin: 0.1em 5.1em;
            transform-origin: 0.1em 5.1em;
            -webkit-animation: load2 2s infinite ease;
            animation: load2 2s infinite ease;
          }
          @-webkit-keyframes load2 {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          @keyframes load2 {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      
    </div>
  );
}

export default App;
